/* Dashboard.css */
.dashboard {
  height: 100vh;
  overflow-x: hidden;
}

.sidebar {
  width: 250px;
  background-color: #333;
  color: white;
  transition: all 0.3s;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.sidebar.collapsed {
  margin-left: -250px;
}

.sidebar-header {
  background-color: #222;
  flex-shrink: 0;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.settings-button {
  color: white;
  background: none;
  border: none;
}

.sidebar-nav {
  padding-top: 20px;
  overflow-y: auto;
  flex-grow: 1;
}

.sidebar-nav .nav-link {
  color: #fff;
  padding: 10px 20px;
  background: none;
  border: none;
  text-align: left;
  width: 100%;
}

.sidebar-nav .nav-link:hover {
  background-color: #444;
}

.main-content {
  flex-grow: 1;
  margin-left: 250px;
  transition: all 0.3s;
}

.main-content.sidebar-collapsed {
  margin-left: 0;
  width: 100%;
}

.main-header {
  background-color: white;
  border-bottom: 1px solid #ddd;
  display: flex;           /* Thêm dòng này */
  justify-content: space-between;  /* Thêm dòng này */
  align-items: center;     /* Thêm dòng này */
  width: 100%;            /* Thêm dòng này */
  padding: 0 20px;  
}

.menu-toggle {
  background-color: #333;
  color: white;
  border: none;
}

.power-bi-container {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  position: relative;
  overflow: hidden;
}

.power-bi-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.header-right {
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  gap: 10px;
  }
.welcome-text {
  color: #333;
  font-weight: 500;
}
@media (max-width: 767.98px) {
  .menu-toggle {
    display: block; /* Show on mobile */
  }
  
  .sidebar {
    /* transform: translateX(-100%); */
    /* width: 100%;  */
  }
  
  .sidebar.collapsed {
    transform: translateX(0);
  }
  
  .main-content {
    margin-left: 0;
    padding-top: 60px; 
    width: 100%;
  }
  
  .main-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
}

/* Thêm các styles mới cho cấu trúc lồng nhau */
.sidebar-nav .nav-item .nav-link {
  padding-left: 1rem;
}

.sidebar-nav .nav-item .nav .nav-item .nav-link {
  padding-left: 2rem;
}

.sidebar-nav .nav-item > button.nav-link {
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  color: inherit;
}

.sidebar-nav .nav-item > button.nav-link:hover {
  background-color: rgba(0,0,0,0.1);
}